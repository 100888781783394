<template>
  <div v-if="showAutomationButtons">
    <button @click="clickAllAnalyzeButtons">Click All Analyze Game Buttons</button>
    <button @click="clickAllShareButtons">Click All Share Buttons</button>
    <button @click="logGamesAnalysis">Log Games Analysis</button>
    <button @click="toggleGreenOverlayAndLogo">Toggle Green Overlay</button>
  </div>

  <CustomMatchup @fetch-strategy="fetchBettingStrategy" />

  <SportSelect style="margin-bottom: 15px;" />

  <div v-for="(oddsInfo, gameId) in store.sportOdds" :key="gameId" class="game-container"
    :id="`game-container-${gameId}`">

    <!-- <Stream :gameId="gameId" v-if="streamLogs[gameId]" /> -->

    <div :id="`game-screenshot-container-${gameId}`" class="game-screenshot-container">
      <div class="row">
        <div class="matchup-info-container">

          <div class="matchup-info">
            <div class="team-info">
              <!-- Home Team Logo and Name -->
              <div class="logo-and-name">
                <img :src="`/team_logos/${store.selectedSport}/${oddsInfo.home_team}.png`" class="team-logo" @click="handleClicks(gameId)"
                  alt="Home Team Logo" />
                <span>{{ oddsInfo.home_team }}</span>
              </div>
              <span class="vs">vs</span>
              <!-- Away Team Logo and Name -->
              <div class="logo-and-name">
                <img :src="`/team_logos/${store.selectedSport}/${oddsInfo.away_team}.png`" class="team-logo" @click="handleClicks(gameId)"
                  alt="Away Team Logo" />
                <span>{{ oddsInfo.away_team }}</span>
              </div>
            </div>
          </div>
          <!-- <div class="game-time" v-html="formatGameTime(oddsInfo.game_time)"></div> -->
          <div class="game-time" @click="handleClicks(gameId)" v-html="formatGameTime(oddsInfo.game_time)"></div>
        </div>
        <div class="matchup-info">
          <!-- <div class="brand" > -->
          <div class="brand" style="display: none">
            <img src="/logo-ai.png" alt="betBOSS.ai" style="height: 30px; margin-right: 20px" />
          </div>
        </div>
      </div>
      <!-- Odds Information -->
      <Odds :game="oddsInfo" />
    </div>
    <div class="buttons-container" style="margin-top: 10px;">
      <button :id="`analyze-button-${gameId}`" class="clickable-button analyze-button"
        :class="{ 'analyze-button-complete': llmResponses[gameId] }"
        @click="() => fetchBettingStrategy(`${oddsInfo.home_team} vs ${oddsInfo.away_team}`, formatOdds(oddsInfo.odds), gameId)"
        :disabled="llmResponses[gameId]">
        <span v-if="llmLoading[gameId]" class="loading-indicator" style="white-space: nowrap">
          <i class="fas fa-spinner fa-spin"></i> THINKING... <br />Up to 30 seconds.
        </span>
        <span v-else-if="llmResponses[gameId]">Analysis Complete
          <i class="fas fa-check"></i>
        </span>
        <span v-else>Analyze Game</span>
      </button>
      <button class="clickable-button share-button" v-if="llmResponses[gameId]" @click="shareGameScreenshot(gameId)"
        style="max-width: 125px">
        SHARE<i class="fas fa-share"></i>
      </button>
    </div>

    <div v-if="llmResponses[gameId]" class="details-container">
      <details open>
        <summary style="cursor: pointer; transition: all 0.25s ease">Game Analysis</summary>
        <div class="llm-response">

          <!-- GREEN STRIPE STUFF -->

          <div v-if="showGreenOverlay">
            <div class="analysis-title" style="font-family:sans-serif">
              <div class="sport-name"> <img src="../assets/logo-ai-dark.png" alt="betBOSS.ai"
                  style="height: 30px; margin-right: 20px" />
              </div>
              <div class="sport-name"> <i style="margin-right: 5px;"></i> FREE PICK </div>
              <div class="sport-name">
                <i :class="`fa ${mapsStore.getSportIdNameIcon.find(s => s.id === store.selectedSport)?.icon}`"
                  style="margin-right: 5px;"></i>
                {{ store.sportDisplayName }}
              </div>
              <div class="sport-time">
                {{ formatGameTime(oddsInfo.game_time).split(' @ ')[0] }} {{ new Date().getFullYear() }}
              </div>
              <!-- <div class="sport-name" style="font-size: 34px;"> <i style="margin-right: 5px;"></i>https://betboss.ai</div> -->
            </div>
          </div>

          <!-- GREEN STRIPE STUFF -->


          {{ llmResponses[gameId].answer }}
        </div>
      </details>
      <details v-if="llmResponses[gameId].sources" class="sources">
        <summary>Sources</summary>
        <ul>
          <template v-for="source in llmResponses[gameId].sources.split('\n\n')">
            <li v-if="source.trim()" :key="source">
              <div>
                <div>{{ source.split("\n")[0] }}</div>
                <a :href="source.split('\n')[1].split(': ')[1]" target="_blank">{{ source.split("\n")[1].split(": ")[1]
                  }}</a>
                <div>{{ source.split("\n")[2] }}</div>
              </div>
            </li>
          </template>
        </ul>
      </details>
      <details v-if="llmResponses[gameId].errors && llmResponses[gameId].errors.length > 0" class="errors">
        <summary>Errors</summary>
        <div>
          <ul>
            <li v-for="error in llmResponses[gameId].errors" :key="error">{{ error }}</li>
          </ul>
        </div>
      </details>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, computed, watch, onUnmounted } from "vue";
import axios from "axios";
import { useStore } from "../stores/store";
import { useMapsStore } from "../stores/maps"; // Import the maps store
import html2canvas from "html2canvas";
import Stream from './Stream.vue';
import Odds from './Odds.vue';
import SportSelect from './SportSelect.vue';
import CustomMatchup from './CustomMatchup.vue';

const store = useStore();
const mapsStore = useMapsStore();
const streamLogs = computed(() => store.streamLogs);
const gameMatchupCustom = ref("");
const customResponse = ref(null);
const customLoading = ref(false);
const llmResponses = reactive({});
const llmLoading = reactive({});
const showAutomationButtons = ref(false);

const showGreenOverlay = ref(false);

const clickAllAnalyzeButtons = () => {
  localStorage.clear(); // Clear local storage before clicking buttons
  document.querySelectorAll('.clickable-button.analyze-button').forEach(button => {
    button.click();
  });
};

// Add this new method to click all share buttons
const clickAllShareButtons = () => {
  document.querySelectorAll('.clickable-button.share-button').forEach(button => {
    button.click();
  });
};

const logGamesAnalysis = async () => {
  const gamesAnalysis = Object.keys(store.sportOdds).map(gameId => {
    const gameInfo = store.sportOdds[gameId];
    const analysis = llmResponses[gameId] ? llmResponses[gameId].answer : 'No analysis available';

    // Format the date and time similar to the shareGameScreenshot function
    const gameDate = new Date(gameInfo.game_time);
    const formattedDate = `${gameDate.getMonth() + 1}-${gameDate.getDate()}`;
    const formattedTime = `${gameDate.getHours()}-${gameDate.getMinutes()}PM`;

    // Replace spaces with underscores for home and away team names and date/time
    const homeTeam = gameInfo.home_team.replace(/ /g, "_");
    const awayTeam = gameInfo.away_team.replace(/ /g, "_");
    // const screenshotFileName = `https://betboss.ai/analysis_screenshot/${homeTeam}_vs_${awayTeam}_${formattedDate}_${formattedTime}.jpg`.replace(/ /g, "_").replace(/-_/g, "-");
    const screenshotFileName = `https://betboss.ai/analysis_screenshot/${gameId}_${homeTeam}_vs_${awayTeam}_overlay.jpg`.replace(/ /g, "_").replace(/-_/g, "-");
    // const screenshotFileName = `back/batch_processing/automation/screenshots/${gameId}_${homeTeam}_vs_${awayTeam}_overlay.png`.replace(/ /g, "_").replace(/-_/g, "-");

    return {
      gameId: gameId,
      homeTeam: gameInfo.home_team,
      awayTeam: gameInfo.away_team,
      screenshotFileName: screenshotFileName,
      analysis: analysis,
    };
  });

  const jsonString = JSON.stringify(gamesAnalysis, null, 2);
  console.log(jsonString);

  try {
    await navigator.clipboard.writeText(jsonString);
    console.log('Games analysis copied to clipboard.');
  } catch (err) {
    console.error('Failed to copy games analysis to clipboard:', err);
  }
  // Create an HTML element with the JSON data
  const gamesAnalysisJsonContainer = document.createElement('div');
  gamesAnalysisJsonContainer.id = 'gamesAnalysisJsonContainer';
  gamesAnalysisJsonContainer.style.display = 'block'; // Make the element visible
  gamesAnalysisJsonContainer.style.position = 'fixed'; // Make it fixed on the screen
  gamesAnalysisJsonContainer.style.top = '10px'; // Position it at the top of the screen
  gamesAnalysisJsonContainer.style.left = '10px'; // Position it on the left of the screen
  gamesAnalysisJsonContainer.style.width = '1px'; // Give it a width of 1px
  gamesAnalysisJsonContainer.style.height = '1px'; // Give it a height of 1px
  gamesAnalysisJsonContainer.style.overflow = 'hidden'; // Hide overflow content
  gamesAnalysisJsonContainer.style.backgroundColor = '#333'; // Dark background color
  gamesAnalysisJsonContainer.style.color = '#ccc'; // Light text color for readability
  gamesAnalysisJsonContainer.style.zIndex = '-10000'; // Make sure it's on top
  gamesAnalysisJsonContainer.style.border = 'none'; // No border
  gamesAnalysisJsonContainer.style.padding = '0'; // No padding
  gamesAnalysisJsonContainer.textContent = jsonString;
  document.body.appendChild(gamesAnalysisJsonContainer);
  alert('Displaying games analysis data.')
  // Optionally, remove the element after a delay if it should only be temporary
  // setTimeout(() => {
  //   document.body.removeChild(gamesAnalysisJsonContainer);
  // }, 3000); // Removes the element after 10 seconds
};


async function shareGameScreenshot(gameId) {
  // const gameContainer = document.querySelector(`#game-screenshot-container-${gameId}`);
  const gameContainer = document.querySelector(`#game-container-${gameId}`);
  if (!gameContainer) return;

  const brandElements = gameContainer.querySelectorAll(".brand");
  brandElements.forEach((el) => (el.style.display = "block"));
  const analysisTitleElements = gameContainer.querySelectorAll(".analysis-title");
  const buttonsContainerElements = gameContainer.querySelectorAll(".buttons-container");

  // Show the elements for the screenshot
  brandElements.forEach(el => el.style.display = "block");
  analysisTitleElements.forEach(el => el.style.display = "block"); // Show the analysis title
  // buttonsContainerElements.forEach(el => el.style.display = "none"); // Hide the buttons container

  const canvas = await html2canvas(gameContainer, {
    backgroundColor: "#222",
    ignoreElements: (element) => {
      return element.classList.contains("sources") || element.classList.contains("errors");
    },
  });

  brandElements.forEach(el => el.style.display = "none");
  analysisTitleElements.forEach(el => el.style.display = "none"); // Hide the analysis title again
  buttonsContainerElements.forEach(el => el.style.display = ""); // Show the buttons container again

  // Specify 'image/jpeg' and quality (e.g., 1 for 90% quality)
  const image = canvas.toDataURL("image/jpeg", 1);
  const link = document.createElement("a");

  const gameInfo = store.sportOdds[gameId];
  if (!gameInfo) return;

  const homeTeam = gameInfo.home_team.replace(/ /g, "_");
  const awayTeam = gameInfo.away_team.replace(/ /g, "_");
  const gameDate = new Date(gameInfo.game_time);

  const formattedDate = `${gameDate.getMonth() + 1}-${gameDate.getDate()}`.replace(/ /g, "_");
  const formattedTime = `${gameDate.getHours()}-${gameDate.getMinutes()}PM`.replace(/ /g, "_");

  const filename = `${homeTeam}_vs_${awayTeam}_${formattedDate}_${formattedTime}.jpg`.replace(/ /g, "_");

  link.download = filename;
  link.href = image;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function formatGameTime(dateTimeStr) {
  return dateTimeStr;
  const now = new Date();
  const currentYear = now.getFullYear();

  // Split the dateTimeStr to insert the current year correctly
  const parts = dateTimeStr.split('@');
  const fullDateTimeStr = `${parts[0]} ${currentYear} @${parts[1]}`;
  const gameDate = new Date(fullDateTimeStr);

  console.log(`Current time: ${now.toISOString()}`);
  console.log(`Game time: ${gameDate.toISOString()}`);

  // Check if the game is currently live
  const isLive = gameDate <= now;

  console.log(`Is live: ${isLive}`);

  // Add a live indicator with a broadcasting icon if the game is currently live
  return isLive ? `${fullDateTimeStr} - ( <span style="color: red;">Live <i class="fas fa-broadcast-tower"></i></span> )` : fullDateTimeStr;
}

function formatOdds(odds) {
  return JSON.stringify(odds, null, 2);
}

async function fetchBettingStrategy(matchup: string, oddsContext: string = "", gameId?: string) {
  const loadingKey = gameId || "custom";
  llmLoading[loadingKey] = true;

  // Function to parse cookies and get the value by name
  function getCookieValue(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      // Use replace to strip quotation marks
      return parts.pop().split(';').shift().replace(/"/g, '');
    }
  }
  // Get username from cookie
  const userName = getCookieValue('user_name') || 'defaultUser';

  try {
    // First, fetch the betting strategy as before
    const response = await axios.post("https://back.betboss.ai/ask_and_markup", {
      search_query: matchup,
      odds_context: oddsContext,
      game_id: gameId,
      league: store.selectedSport
    }, {
      withCredentials: true,
    });

    // Assuming the response structure is as described
    const { answer, errors, sources, markup, search_count } = response.data;

    // Update the store with the new search count
    store.searchCount = search_count;

    if (gameId) {
      // Parse the markup JSON string
      const markup = JSON.parse(response.data.markup);

      // Update the odds for the gameId with the markup odds
      if (store.sportOdds[gameId]) {
        store.sportOdds[gameId].odds = markup;
      }

      llmResponses[gameId] = {
        answer: response.data.answer,
        errors: response.data.errors,
        sources: response.data.sources,
        markup: markup,
      };
    } else {
      // Handle customResponse similarly if needed
      const markup = JSON.parse(response.data.markup);
      customResponse.value = {
        answer: response.data.answer,
        markup: markup,
      };
    }

  } catch (error) {
    console.error(error);
    const errorMessage = error.response && error.response.status === 429 ? `Error fetching response: ${error.response.data.detail}` : `Error fetching response: ${error.message}`;

    if (gameId) {
      llmResponses[gameId] = { answer: errorMessage, errors: [], sources: "", markup: {} };
    } else {
      customResponse.value = { answer: errorMessage, markup: {} };
    }
  } finally {
    llmLoading[loadingKey] = false;
  }
}

const clickCounts = ref({});

const resetAndRerunAnalysis = async (gameId) => {
  try {
    console.log(`Deleting game search for gameId: ${gameId}`);
    const response = await axios.delete(`https://back.betboss.ai/delete-game-search/${gameId}`);
    // Check if the response data has a message and includes the success text
    if (response.data && response.data.message && response.data.message.includes('deleted successfully')) {
      clickAnalyzeButton(gameId);
    } else {
      console.error('Failed to delete game search:', response.data.message || 'No message received');
    }
    clickAnalyzeButton(gameId);

  } catch (error) {
    // Log the error message or the entire error if no message is available
    console.error('Error deleting game search:', error.response?.data?.error || error.message || error);
  }
};

const clickAnalyzeButton = (gameId) => {
  const button = document.querySelector(`#analyze-button-${gameId}`);
  if (button) {
    button.click();
  }
};

const handleClicks = (gameId) => {
  if (!clickCounts.value[gameId]) {
    clickCounts.value[gameId] = 0;
  }
  clickCounts.value[gameId]++;

  setTimeout(() => {
    if (clickCounts.value[gameId] === 3) {
      resetAndRerunAnalysis(gameId);
    }
    clickCounts.value[gameId] = 0;
  }, 400); // 400 ms for triple click
};

const toggleButtonVisibility = (event) => {
  // Key chord: Ctrl + Alt + G
  if (event.ctrlKey && event.altKey && event.key === 'g') {
    console.log('Clearing local storage and setting brand visibility to block.');
    localStorage.clear();
    const logoElements = document.querySelectorAll('.brand');
    logoElements.forEach(el => {
      el.style.display = 'block'; // Always set to block when Ctrl + Alt + G is pressed
    });
    console.log('Local storage cleared and brand visibility set to block.');
    showAutomationButtons.value = !showAutomationButtons.value;
  }
  // Key chord: Ctrl + Alt + r
  if (event.ctrlKey && event.altKey && event.key === 'r') {
    console.log('Resetting and rerunning analysis for all games.');
    Object.keys(store.sportOdds).forEach(gameId => {
      console.debug(`Resetting and rerunning analysis for gameId: ${gameId}`);
      resetAndRerunAnalysis(gameId);
    });
  }

  // Key chord: Ctrl + Alt + T
  if (event.ctrlKey && event.altKey && event.key === 't') {
    const gameAnalysisDetails = document.querySelectorAll('.details-container > details:first-child');
    gameAnalysisDetails.forEach(detail => {
      detail.open = !detail.open; // Toggle the open state of the game analysis details element
    });
  }
};
window.addEventListener('keydown', toggleButtonVisibility);

const toggleGreenOverlayAndLogo = () => {
  showGreenOverlay.value = !showGreenOverlay.value;
  const logoElements = document.querySelectorAll('.brand');
  logoElements.forEach(el => {
    el.style.display = 'block'; 
  });
};



onUnmounted(() => {
  console.debug('Removing keydown event listener for toggleButtonVisibility.'); // Debug: log event listener removal
  window.removeEventListener('keydown', toggleButtonVisibility);
});
</script>

<style scoped>
@import "@/assets/games.css";

.team-info {
  display: flex;
  align-items: center;
}

.logo-and-name {
  display: flex;
  align-items: center;
}

.team-logo {
  height: 20px;
  width: auto;
  margin-right: 8px;
}

.game-time {
  margin-bottom: 5px;
}

.vs {
  margin-right: 5px;
  margin-left: 5px;
}


.buttons-container {
  display: flex;
  width: 100%;
}

.share-button,
.analyze-button {
  flex: 1;
  width: 50%;
}

.game-screenshot-container {
  padding: 10px;
}

.details-container {
  position: relative;
  /* padding-top: 60px; */
}

.analysis-title {
  position: absolute;
  top: 200px;
  left: 0;
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  color: #000;
  background-color: rgba(103, 209, 103, 0.8);
  padding: 10px 0;
  text-align: center;
  z-index: 1;
}

.sport-name {
  font-size: 56px;
  font-weight: bold;
}

.sport-time {
  font-size: 45px;
  font-weight: bold;
}


.share-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  /* Bolder text */
  cursor: pointer;
}

.share-button i.fas.fa-share {
  margin-left: 8px;
  vertical-align: middle;
  /* Center icon with text */
}
</style>
