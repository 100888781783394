<template>
  <!-- <Config /> -->
  <div class="top-bar">
    <!-- <div class="logo">betBOSS.ai</div> -->
    <div class="logo">
      <!-- <img src="../assets/logo.png" alt="betBOSS.ai" />
      <img src="../assets/logo2.png" alt="betBOSS.ai" />
      <img src="../assets/logo3.png" alt="betBOSS.ai" /> -->
      <a  href="https://betboss.ai">
        <img src="../assets/logo-ai.png" alt="betBOSS.ai" />
        <small class="slogan">Data Game Strong</small>
      </a>
 
    </div>
    <div style="display: flex; flex-direction: column;">
      <div>
        <!-- <a v-if="!loggedIn" href="https://auth.betboss.ai/register" class="login-link">SIGNUP</a> -->
        <!-- <a v-if="!loggedIn" href="https://back.betboss.ai/protected" class="login-link">SIGNUP/LOGIN</a> -->
        <!-- <a v-if="loggedIn" href="https://auth.betboss.ai/" class="user-email">{{ userEmail }}</a> -->
        <!-- <a v-if="loggedIn" href="https://auth.betboss.ai/" class="user-email">{{ userTier.charAt(0).toUpperCase() + userTier.slice(1) }}</a> -->
        <!-- <a v-if="loggedIn" href="https://auth.betboss.ai/" class="user-email">{{ userTier ? userTier.charAt(0).toUpperCase() + userTier.slice(1) : '' }}</a> -->
        <!-- <a v-if="loggedIn" href="https://back.betboss.ai/logout" class="login-link">LOGOUT</a> -->
        <!-- <a v-if="loggedIn && !userTier" href="https://back.betboss.ai/checkout.html" class="login-link" >UPGRADE</a> -->
        <!-- <a v-if="loggedIn" href="https://back.betboss.ai/checkout.html" class="login-link" style="text-align: justify; display: block; width: 100%;" >UPGRADE</a> -->
        <!-- <a v-if="loggedIn && userTier" href="https://billing.stripe.com/p/login/test_6oEg2O2QjfXJ0Ss4gg" class="login-link" style="text-align: justify; display: block; width: 100%;">ACCOUNT</a> -->
      </div>
      <!-- Display remaining requests -->
      <div class="rate-limit-info" style="display: flex; flex-direction: column; align-items: center;">
        <!-- <div>50 remaining.</div> -->
        <!-- <div>{{ formatResetTime(store.reset) }} mins till reset</div> -->
        <div v-if="!loggedIn && showLoginButton">Signup to increase limit</div>
        <!-- <div >{{ remainingSearchesMessage }}</div> -->
        <!-- <div v-else>{{ store.remainingSearches }} remaining.</div> -->
        <!-- Display search count only if greater than 0 and defined -->
        <div v-if="store.searchCount > 0">Searches: {{ store.searchCount }}/{{ store.allowedSearches }}</div>
        <div style="text-align: center;">
          <div>{{ userEmail }}</div>
          <a v-if="loggedIn && userTier === 'free'" href="https://betboss.ai/stripe"
            style="display: inline-block; margin: 0 auto;">FREE TRIAL</a>
          <a v-if="!loggedIn && showLoginButton" href="https://back.betboss.ai/protected"
            style="display: inline-block; margin: 0 auto;">SIGNUP/LOGIN</a>
          <div v-if="userTier === 'rookie'">Rookie</div>
          <div v-if="userTier === 'pro'">Pro</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "../stores/store";
import { useRouter } from 'vue-router';

const loggedIn = ref(false);
const userEmail = ref("");
const userTier = ref("");
const store = useStore();
const router = useRouter();
const showLoginButton = ref(true);

// Reactive computed property that updates whenever `remainingSearches` changes
const remainingSearchesMessage = computed(() => {
  return `${store.remainingSearches}`;
});

const checkLoginStatus = async () => {
  console.log(document.cookie); // This logs all cookies accessible via JavaScript
  const userNameCookie = getCookie("user_name");
  const userTierCookie = getCookie("tier");
  console.log(userNameCookie); // This logs the value of 'user_name' cookie or null if not found
  console.log(store.userEmail); // This logs the value of 'user_name' cookie or null if not found
  if (userNameCookie) {
    loggedIn.value = true;
    userEmail.value = userNameCookie;
    store.userEmail = userNameCookie; // Add this line to set the email in the store
    if (userTierCookie) {
      userTier.value = userTierCookie; // Set local userTier with the value from the cookie
      store.userTier = userTierCookie; // Also set userTier in the store
    } else {
      // If userTier is not set, then call fetchUserTierAndCalculateRemainingSearches to get the tier and set it
      userTier.value = store.userTier; // Update local userTier with the value from the store
    }
  } else {
    loggedIn.value = false;
    store.userEmail = ""; 
  }
}

function checkAffiliateId() {
  const urlParams = new URLSearchParams(window.location.search);
  const affid = urlParams.get('aff');
  const affS1 = urlParams.get('affS1');
  
  const restrictedAffids = ['712816', '686209', '600593', '628183'];
  
  // Check if we've already stored a restricted affid
  const storedAffid = localStorage.getItem('restrictedAffid');
  
  if (storedAffid && restrictedAffids.includes(storedAffid)) {
    showLoginButton.value = false;
    return;
  }
  
  if ((affid && restrictedAffids.includes(affid)) || (affS1 && restrictedAffids.includes(affS1))) {
    showLoginButton.value = false;
    // Store the restricted affid
    localStorage.setItem('restrictedAffid', affid || affS1);
  }
}

onMounted(async () => {
  await checkLoginStatus();
  checkAffiliateId();
});

watch(() => [store.searchCount, store.allowedSearches], ([newSearchCount, newAllowedSearches]) => {
  if (newSearchCount > newAllowedSearches) {
    window.location.href = 'https://betboss.ai/stripe'; // Redirect to the stripe page
  }
});

// utils.js or within your Vue component script
function getCookie(name: string): string | null {
  let cookieValue: string | null = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.startsWith(name + "=")) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1)).replace(/\"/g, "");
        break;
      }
    }
  }
  return cookieValue;
}
</script>

<style scoped>
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Align items to the center */
  width: 100%;
  position: relative;
  margin-bottom: 5px;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}
/* 
.logo-container {
  display: flex;
  align-items: center;
  text-decoration: none;
} */

.logo img {
  width: 175px;
  height: auto;
}

.logo a {
  color: #4e9f3d;
  font-size: 1.5em;
  letter-spacing: 1px;
  margin-bottom: 3px;

}

.login-link,
.user-email {
  font-size: 18px;
  cursor: pointer;
  color: #4e9f3d;
  text-decoration: none;
  margin-right: 20px;
  font-family: "Franklin Gothic Medium", "Arial Narro w", Arial, sans-serif;
  letter-spacing: 1px;
}

.login-link:hover {
  text-decoration: underline;
  color: #4e9f3d;
}

.slogan {
  display: block;
  color: white;
  font-size: 14px;
  letter-spacing: 1px;
  margin-left: 8px;
  line-height: 0;
  margin-bottom: 12px;
}

.rate-limit-info {
  color: #fff;
  background-color: #333;
  padding: 2px;
  border-radius: 8px;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: left;
  /* Align text to the left */
  display: flex;
  /* Use flexbox to align items */
  flex-direction: column;
  /* Stack items vertically */
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  /* Align items to the start (left) of the container */
}
</style>
