import { createRouter, createWebHistory } from "vue-router";
import Home from "../components/Home.vue";
import Stripe from "../components/Stripe.vue"; // Import the Stripe component
import Odds from "../components/Odds.vue"; // Import the Stripe component
import Arbitrage from "../components/Arbitrage.vue"; // Import the Stripe component
import ReviewGrabber from "../components/ReviewGrabber.vue"; // Import the Stripe component
import Dev from "../components/Dev.vue"; // Import the Stripe component


const routes = [
  { path: "/", name: "Home", component: Home },
  { path: "/arb", name: "Arbitrage", component: Arbitrage },
  { path: "/stripe", name: "Stripe", component: Stripe }, // Add this line
  { path: "/odds", name: "Odds", component: Odds }, // Add this line
  { path: "/review-grabber", name: "ReviewGrabber", component: ReviewGrabber }, // Add this line
  { path: "/dev", name: "Dev", component: Dev }, 
  { path: "/:catchAll(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
