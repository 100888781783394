import { defineStore } from "pinia";

export const useMapsStore = defineStore("maps", {
  state: () => ({
    nfl_abbreviation: {
      "Arizona Cardinals": "ARI",
      "Atlanta Falcons": "ATL",
      "Baltimore Ravens": "BAL",
      "Buffalo Bills": "BUF",
      "Carolina Panthers": "CAR",
      "Chicago Bears": "CHI",
      "Cincinnati Bengals": "CIN",
      "Cleveland Browns": "CLE",
      "Dallas Cowboys": "DAL",
      "Denver Broncos": "DEN",
      "Detroit Lions": "DET",
      "Green Bay Packers": "GB",
      "Houston Texans": "HOU",
      "Indianapolis Colts": "IND",
      "Jacksonville Jaguars": "JAX",
      "Kansas City Chiefs": "KC",
      "Las Vegas Raiders": "LV",
      "Los Angeles Chargers": "LAC",
      "Los Angeles Rams": "LAR",
      "Miami Dolphins": "MIA",
      "Minnesota Vikings": "MIN",
      "New England Patriots": "NE",
      "New Orleans Saints": "NO",
      "New York Giants": "NYG",
      "New York Jets": "NYJ",
      "Philadelphia Eagles": "PHI",
      "Pittsburgh Steelers": "PIT",
      "San Francisco 49ers": "SF",
      "Seattle Seahawks": "SEA",
      "Tampa Bay Buccaneers": "TB",
      "Tennessee Titans": "TEN",
      "Washington Commanders": "WAS"
    },
    nhl_abbreviation: {
      "Atlanta Flames": "AFM",
      "Mighty Ducks of Anaheim/Anaheim Ducks": "ANA",
      "Arizona Coyotes": "ARI",
      "Atlanta Thrashers": "ATL",
      "Boston Bruins": "BOS",
      "Brooklyn Americans": "BRK",
      "Buffalo Sabres": "BUF",
      "Carolina Hurricanes": "CAR",
      "Columbus Blue Jackets": "CBJ",
      "Bay Area Seals/California Golden Seals": "CGS",
      "Calgary Flames": "CGY",
      "Chicago Black Hawks/Blackhawks": "CHI",
      "Cleveland Barons": "CLE",
      "Colorado Rockies": "CLR",
      "Colorado Avalanche": "COL",
      "Dallas Stars": "DAL",
      "Detroit Cougars": "DCG",
      "Detroit Red Wings": "DET",
      "Detroit Falcons": "DFL",
      "Edmonton Oilers": "EDM",
      "Florida Panthers": "FLA",
      "Hamilton Tigers": "HAM",
      "Hartford Whalers": "HFD",
      "Kansas City Scouts": "KCS",
      "Los Angeles Kings": "LAK",
      "Minnesota Wild": "MIN",
      "Montreal Maroons": "MMR",
      "Minnesota North Stars": "MNS",
      "Montreal Canadiens": "MTL",
      "Montreal Wanderers": "MWN",
      "New Jersey Devils": "NJD",
      "Nashville Predators": "NSH",
      "New York Americans": "NYA",
      "New York Islanders": "NYI",
      "New York Rangers": "NYR",
      "California/Oakland Seals": "OAK",
      "Ottawa Senators": "OTT",
      "Philadelphia Flyers": "PHI",
      "Phoenix Coyotes": "PHX",
      "Pittsburgh Pirates": "PIR",
      "Pittsburgh Penguins": "PIT",
      "Quebec Bulldogs": "QBD",
      "Philadelphia Quakers": "QUA",
      "Quebec Nordiques": "QUE",
      "Seattle Kraken": "SEA",
      "Ottawa Senators (original)": "SEN",
      "St. Louis Eagles": "SLE",
      "San Jose Sharks": "SJS",
      "St. Louis Blues": "STL",
      "Toronto Hockey Club/Toronto Arenas": "TAN",
      "Tampa Bay Lightning": "TBL",
      "Toronto Maple Leafs": "TOR",
      "Toronto St. Patricks": "TSP",
      "Vancouver Canucks": "VAN",
      "Vegas Golden Knights": "VGK",
      "Winnipeg Jets (original)": "WIN",
      "Winnipeg Jets": "WPG",
      "Washington Capitals": "WSH"
    },
    nba_abbreviation: {
      "Atlanta Hawks": "ATL",
      "Boston Celtics": "BOS",
      "Brooklyn Nets": "BKN",
      "Charlotte Hornets": "CHA",
      "Chicago Bulls": "CHI",
      "Cleveland Cavaliers": "CLE",
      "Dallas Mavericks": "DAL",
      "Denver Nuggets": "DEN",
      "Detroit Pistons": "DET",
      "Golden State Warriors": "GSW",
      "Houston Rockets": "HOU",
      "Indiana Pacers": "IND",
      "Los Angeles Clippers": "LAC",
      "Los Angeles Lakers": "LAL",
      "Memphis Grizzlies": "MEM",
      "Miami Heat": "MIA",
      "Milwaukee Bucks": "MIL",
      "Minnesota Timberwolves": "MIN",
      "New Orleans Pelicans": "NOP",
      "New York Knicks": "NYK",
      "Oklahoma City Thunder": "OKC",
      "Orlando Magic": "ORL",
      "Philadelphia 76ers": "PHI",
      "Phoenix Suns": "PHX",
      "Portland Trail Blazers": "POR",
      "Sacramento Kings": "SAC",
      "San Antonio Spurs": "SAS",
      "Toronto Raptors": "TOR",
      "Utah Jazz": "UTA",
      "Washington Wizards": "WAS"
    },
    mlb_abbreviation: {
      "Anaheim Angels": "ANA",
      "Arizona Diamondbacks": "AZ",
      "Atlanta Braves": "ATL",
      "Baltimore Orioles": "BAL",
      "Boston Red Sox": "BOS",
      "Boston Americans": "BSA",
      "Boston Doves": "BSD",
      "California Angels": "CAL",
      "Chicago Cubs": "CHC",
      "Chicago White Sox": "CWS",
      "Cincinnati Reds": "CIN",
      "Cleveland Guardians": "CLE",
      "Colorado Rockies": "COL",
      "Detroit Tigers": "DET",
      "Florida Marlins": "FLA",
      "Houston Astros": "HOU",
      "Kansas City Royals": "KC",
      "Los Angeles Angels": "LAA",
      "Los Angeles Dodgers": "LAD",
      "Miami Marlins": "MIA",
      "Milwaukee Brewers": "MIL",
      "Minnesota Twins": "MIN",
      "Montreal Expos": "MTL",
      "New York Yankees": "NY",
      "New York Giants": "NYG",
      "New York Mets": "NYM",
      "New York Highlanders": "NYH",
      "Oakland Athletics": "OAK",
      "Philadelphia Athletics": "PHA",
      "Philadelphia Phillies": "PHI",
      "Philadelphia Blue Jays": "PHB",
      "Pittsburgh Pirates": "PIT",
      "San Diego Padres": "SD",
      "Seattle Mariners": "SEA",
      "San Francisco Giants": "SF",
      "St. Louis Cardinals": "STL",
      "Tampa Bay Rays": "TB",
      "Texas Rangers": "TEX",
      "Toronto Blue Jays": "TOR",
      "Washington Nationals": "WSH"
    },
    sportIdNameIcon: [
      { id: 'americanfootball_nfl', name: 'NFL', icon: 'fa-football' },
      { id: 'basketball_nba', name: 'NBA', icon: 'fa-basketball-ball' },
      { id: 'baseball_mlb', name: 'MLB', icon: 'fa-baseball-ball' },
      { id: 'soccer_uefa_champs_league', name: 'UEFA Champions League', icon: 'fa-futbol' },
      { id: 'icehockey_nhl', name: 'NHL', icon: 'fa-hockey-puck' },
      { id: 'soccer_uefa_european_championship', name: 'UEFA Cup', icon: 'fa-futbol' },
      { id: 'soccer_uefa_nations_league', name: 'UEFA Nations', icon: 'fa-futbol' },
      { id: 'americanfootball_ncaaf', name: 'NCAAF', icon: 'fa-football' },
      { id: 'basketball_ncaab', name: 'NCAA Basketball', icon: 'fa-basketball' },
      { id: 'baseball_ncaa', name: 'NCAA Baseball', icon: 'fa-baseball' },
      { id: 'mma_mixed_martial_arts', name: 'MMA', icon: 'fa-hand-back-fist' },
      { id: 'soccer_epl', name: 'EPL', icon: 'fa-futbol' },
      { id: 'soccer_usa_mls', name: 'MLS', icon: 'fa-futbol' },
    ]

  }),

  getters: {
    sportAbbreviationVariableMap: (state) => {
      return {
        "basketball_nba": state.nba_abbreviation,
        "baseball_mlb": state.mlb_abbreviation,
        "icehockey_nhl": state.nhl_abbreviation,
        "americanfootball_nfl": state.nfl_abbreviation,
      };
    },
    getSportIdNameIcon: (state) => {
      return state.sportIdNameIcon;
    },
    getAbbreviation: (state) => (sport, teamName) => {
      // Adjust the sport key to match the store's keys if necessary
      let sportKey = sport;
      switch (sport) {
        case 'nfl': sportKey = 'americanfootball_nfl'; break;
        case 'nhl': sportKey = 'icehockey_nhl'; break;
        case 'nba': sportKey = 'basketball_nba'; break;
        case 'mlb': sportKey = 'baseball_mlb'; break;
        case 'uefa_champs_league': sportKey = 'soccer_uefa_champs_league'; break;
        case 'uefa': sportKey = 'soccer_uefa_european_championship'; break;
        // Add more cases as needed
      }
      const sportMap = state.sportAbbreviationVariableMap[sportKey];
      if (!sportMap) {
        console.warn(`No abbreviation mapping found for sport: ${sportKey}`);
        return teamName; // Fallback to the team name if the sport mapping is not found
      }
      return sportMap[teamName] || teamName; // Fallback to the team name if the team abbreviation is not found
    },
  },
  actions: {

  }
});
