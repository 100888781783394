<template>
  <div ref="gameContainer" class="game-container"></div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';

const gameContainer = ref<HTMLDivElement | null>(null);
const WIDTH = 2200; // Changed from 800 to 1600
const HEIGHT = 1200; // Changed from 800 to 1600

const NEON_COLORS = [
  '#FF00FF', // Neon Pink
  '#00FF00', // Neon Green
  '#00FFFF', // Neon Cyan
  '#FFFF00', // Neon Yellow
  '#FF8000'  // Neon Orange
];

class Bug {
  x: number;
  y: number;
  color: string;
  size: number;

  constructor(x: number, y: number, color: string) {
    this.x = x;
    this.y = y;
    this.color = color;
    this.size = 4;
  }

  move() {
    this.x += Math.floor(Math.random() * 3) - 1;
    this.y += Math.floor(Math.random() * 3) - 1;
    this.x = Math.max(0, Math.min(this.x, WIDTH - this.size));
    this.y = Math.max(0, Math.min(this.y, HEIGHT - this.size));
  }

  draw(ctx: CanvasRenderingContext2D) {
    ctx.fillStyle = this.color;
    ctx.fillRect(this.x, this.y, this.size, this.size);
  }
}

const bugs: Bug[] = [];

function createBugs() {
  NEON_COLORS.forEach(color => {
    const clusterX = Math.floor(Math.random() * (WIDTH - 100)) + 50;
    const clusterY = Math.floor(Math.random() * (HEIGHT - 100)) + 50;
    for (let i = 0; i < 20; i++) {
      let x = clusterX + Math.floor(Math.random() * 61) - 30;
      let y = clusterY + Math.floor(Math.random() * 61) - 30;
      x = Math.max(0, Math.min(x, WIDTH - 4));
      y = Math.max(0, Math.min(y, HEIGHT - 4));
      bugs.push(new Bug(x, y, color));
    }
  });
}

let animationFrameId: number;

function gameLoop(ctx: CanvasRenderingContext2D) {
  ctx.fillStyle = '#000000';
  ctx.fillRect(0, 0, WIDTH, HEIGHT);

  bugs.forEach(bug => {
    bug.move();
    bug.draw(ctx);
  });

  animationFrameId = requestAnimationFrame(() => gameLoop(ctx));
}

onMounted(() => {
  if (gameContainer.value) {
    const canvas = document.createElement('canvas');
    canvas.width = WIDTH;
    canvas.height = HEIGHT;
    gameContainer.value.appendChild(canvas);

    const ctx = canvas.getContext('2d');
    if (ctx) {
      createBugs();
      gameLoop(ctx);
    }
  }
});

onUnmounted(() => {
  cancelAnimationFrame(animationFrameId);
});
</script>

<style scoped>
.game-container {
  width: 1600px;
  height: 1600px;
  margin: 0 auto;
}

</style>
